<template>
  <div class="page-quizzes">
    <div class="d-flex align-items-center justify-content-between">
      <div class="title">
        <h3>Quizzes</h3>
        <p>Add, edit or remove quizzes</p>
      </div>

      <CButton color="primary" class="iemq m-0 add-button" @click="addQuiz">
        Add Quiz <strong class="ml-2">+</strong>
      </CButton>
    </div>

    <div class="my-2 mx-0 mb-4 filters">
      <input class="form-control" type="text" placeholder="Search quiz" />
    </div>

    <div class="d-flex align-items-start justify-content-start flex-wrap">
      <div class="quiz-card mr-3 mb-3" v-for="quiz in quizzes" :key="quiz.id">
        <div class="color" :style="{ background: quiz.color }"></div>
        <router-link :to="'/quizzes/' + quiz.id"
          ><h5>{{ quiz.name }}</h5></router-link
        >
        <div>
          <span>{{ quiz.games }} times played</span>
          <small>{{ new Date(quiz.created_at).toDateString() }}</small>
        </div>

        <CDropdown color="white p-2" placement="bottom-start" :caret="false">
          <template #toggler-content>
            <svg
              width="4"
              height="16"
              viewBox="0 0 4 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M2 4C3.1 4 4 3.1 4 2C4 0.9 3.1 0 2 0C0.9 0 0 0.9 0 2C0 3.1 0.9 4 2 4ZM2 6C0.9 6 0 6.9 0 8C0 9.1 0.9 10 2 10C3.1 10 4 9.1 4 8C4 6.9 3.1 6 2 6ZM2 12C0.9 12 0 12.9 0 14C0 15.1 0.9 16 2 16C3.1 16 4 15.1 4 14C4 12.9 3.1 12 2 12Z"
                fill="#333333"
              />
            </svg>
          </template>

          <CDropdownItem :to="'/quizzes/' + quiz.id">
            <CIcon name="cil-external-link" class="mr-2" /> Edit
          </CDropdownItem>
          <!-- <CDropdownItem>
            <CIcon name="cil-clone" class="mr-2" /> Clone
          </CDropdownItem> -->
          <!-- <CDropdownItem>
            <CIcon name="cil-pencil" class="mr-2" /> Rename
          </CDropdownItem>
          <CDropdownItem>
            <CIcon name="cil-paint" class="mr-2" /> Change color
          </CDropdownItem> -->
          <CDropdownItem @click="copyFrontUrl(quiz)">
            <CIcon name="cil-link-alt" class="mr-2" />
            Copy link
          </CDropdownItem>
          <!-- <CDropdownItem>
            <CIcon name="cil-spreadsheet" class="mr-2" /> Results
          </CDropdownItem> -->
          <CDropdownItem class="text-danger" @click="tryToDeleteQuiz(quiz)">
            <CIcon name="cil-trash" class="mr-2" /> Remove
          </CDropdownItem>
        </CDropdown>
      </div>
    </div>

    <CModal :show.sync="deleteModal">
      <p>
        Delete this quiz permanently?
      </p>

      <template #footer>
        <div class="d-flex align-items-center justify-content-center w-100">
          <CButton
            @click="cancelDeleteConfirmation"
            color="outline-primary"
            class="iemq mr-3"
            >No</CButton
          >
          <CButton
            @click="acceptDeleteConfirmation"
            color="primary"
            class="iemq"
            >Yes, I'm sure</CButton
          >
        </div>
      </template>
    </CModal>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import axios from "axios";

const apiUrl = process.env.VUE_APP_API_URL || "http://localhost:1337";
const frontUrl = process.env.VUE_APP_FRONT_URL || "http://localhost:3000";

export default {
  name: "PQuizzes",

  data() {
    return {
      selectedQuizzes: [],
      fields: [
        { key: "quizname", label: "Quizname", _classes: "font-weight-bold" },
        { key: "email", label: "Email" },
        { key: "created_at", label: "Created" },
        { key: "blocked", label: "Status" },
        { key: "actions", label: "" },
      ],
      activePage: 1,
      deleteModal: false,
    };
  },

  computed: {
    ...mapState({
      jwtToken: (state) => state.auth.jwt,
      quizzes: (state) => state.quiz.quizzes,
    }),
  },

  watch: {
    $route: {
      immediate: true,
      handler(route) {
        if (route.query && route.query.page) {
          this.activePage = Number(route.query.page);
        }
      },
    },
  },

  async created() {
    try {
      await this.startLoading();
      await this.fetchQuizzes();
    } catch (error) {
      console.log({ error });
    } finally {
      await this.stopLoading();
    }
  },

  methods: {
    ...mapActions({
      startLoading: "app/startLoading",
      stopLoading: "app/stopLoading",
      setQuizzes: "quiz/setQuizzes",
      clearCurrentQuiz: "quiz/clearCurrentQuiz",
    }),

    copyFrontUrl(quiz) {
      const quizUrl = `${frontUrl}?quiz=${quiz.id}&token=${quiz.token.token}`;

      const clipboardData =
        event.clipboardData ||
        window.clipboardData ||
        event.originalEvent?.clipboardData ||
        navigator.clipboard;

      clipboardData.writeText(quizUrl);

      this.$toasted.success("Link copied", {
        icon: "check_circle",
      });
    },

    async fetchQuizzes() {
      const quizzesResponse = await axios.get(`${apiUrl}/quizzes/?_limit=-1`, {
        headers: {
          Authorization: `Bearer ${this.jwtToken}`,
        },
      });

      await this.setQuizzes(quizzesResponse.data);
      await this.clearCurrentQuiz();
    },

    async deleteQuizzes() {
      for (const quiz of this.selectedQuizzes) {
        await axios.delete(`${apiUrl}/quizzes/${quiz.id}`, {
          headers: {
            Authorization: `Bearer ${this.jwtToken}`,
          },
        });
      }
    },

    quizClicked(item) {
      this.$router.push({ path: `${item.id}` });
    },

    addQuiz() {
      this.$router.push({ path: `quizzes/add` });
    },

    tryToDeleteQuiz(quiz) {
      this.deleteModal = true;
      this.selectedQuizzes = [{ ...quiz }];
    },

    cancelDeleteConfirmation() {
      this.deleteModal = false;
    },

    async acceptDeleteConfirmation() {
      this.deleteModal = false;

      try {
        await this.startLoading();
        await this.deleteQuizzes();
        await this.fetchQuizzes();
      } catch (error) {
        console.log({ error });
      } finally {
        await this.stopLoading();
      }
    },

    pageChange(val) {
      this.$router.push({ query: { page: val } });
    },
  },
};
</script>

<style lang="scss" scoped>
.page-quizzes {
  padding: 32px 24px;

  .add-button {
    strong {
      font-size: 30px;
      font-weight: 100;
    }
  }

  .filters {
    max-width: 330px;
  }

  .quiz-card {
    border: 1px solid #e1e1e1;
    padding: 30px 12px 15px 12px;
    background: #fff;
    position: relative;
    max-width: 240px;
    width: 100%;
    min-height: 140px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;

    h5 {
      font-style: normal;
      font-weight: 600;
      font-size: 15px;
      line-height: 18px;
      color: #333333;
      padding-right: 40px;
      margin-bottom: 8px;
      display: inline-block;
      word-break: break-word;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      line-height: 16px;
      max-height: 32px;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }

    span {
      font-style: normal;
      font-weight: normal;
      font-size: 15px;
      line-height: 18px;
      color: #333333;
      margin-bottom: 8px;
    }

    small {
      font-style: normal;
      font-weight: normal;
      font-size: 15px;
      line-height: 18px;
      color: #9d9d9c;
    }

    .color {
      height: 15px;
      width: 100%;
      position: absolute;
      top: 0;
      left: 0;
    }

    span {
      display: block;
    }

    .dropdown {
      position: absolute;
      right: 5px;
      top: 20px;
    }
  }

  ::v-deep {
    .mfe-2 {
      display: none;

      & + input {
        min-width: 330px;
      }
    }

    .table {
      display: flex;
      flex-flow: column nowrap;
      flex: 1 1 auto;
      border: 0;
      margin: 0;

      thead {
        margin-bottom: 8px;
        margin-top: 19px;

        tr {
          th {
            font-weight: 600;
            font-size: 15px;
            line-height: 18px;
            text-align: center;
            color: #9d9d9c;
          }
        }
      }

      tbody {
        tr {
          background: #ffffff;
          border: 1px solid #e1e1e1;
          box-sizing: border-box;
          margin-bottom: 8px;
          min-height: 80px;

          td {
            word-break: break-word;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            text-align: center;
            display: block;
            font-size: 15px;
            line-height: 18px;

            .status-badge {
              font-style: normal;
              font-weight: bold;
              font-size: 15px;
              line-height: 18px;
              color: #ffffff;
              padding: 8px;
              border-radius: 38px;
              display: inline-flex;
              flex-direction: row;
              justify-content: center;
              align-items: center;
              min-width: 100px;
            }

            &.actions-cell {
              padding-top: 6px !important;
              text-align: right;
              overflow: visible;
            }
          }
        }
      }

      tr {
        width: 100%;
        display: flex;
        flex-flow: row nowrap;
        align-items: center;

        td,
        th {
          display: flex;
          flex-flow: row nowrap;
          flex-grow: 1;
          flex-basis: 0;
          min-width: auto;
          border: 0;
          padding: 0 10px;

          & > div {
            width: 100%;
          }
        }
      }
    }
  }
}
</style>
